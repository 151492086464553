import { createGlobalStyle } from "styled-components";

import gelionBlack from "./Gelion_Black.otf";
import gelionBold from "./Gelion_Bold.otf";
import gelionRegular from "./Gelion_Regular.otf";

export const OomiFonts = createGlobalStyle`
  @font-face {
    font-family: 'Gelion';
    src: url(${gelionRegular}) format('opentype');
    font-display: fallback;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Gelion';
    src: url(${gelionBold}) format('opentype');
    font-display: fallback;
    font-weight: 700;
  }
  @font-face {
    font-family: 'Gelion Black';
    src: url(${gelionBlack}) format('opentype');
    font-display: fallback;
    font-weight: 700;
  }

  html {
    font-size: 16px;
  }
`;
