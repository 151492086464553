import { Theme, createTheme } from "@vapaus/ui-v2";

const fontFamily = '"Gelion","Helvetica","Arial",sans-serif';
const fontFamilyHeading = '"Gelion Black","Helvetica","Arial",sans-serif';

export const oomiTheme: Theme = createTheme({
  palette: {
    primary1: {
      main: "#092651",
      light: "#6d7d97",
      lighter: "#cfd4dc",
    },
    primary2: {
      main: "#F9E1B7",
      light: "#f9edd4",
      lighter: "#fdf9f1",
    },
    secondary1: {
      main: "#9E699B",
      light: "#c2a6c3",
      lighter: "#ebe1eb",
    },
    secondary2: {
      main: "#FCF050",
      light: "#fcf597",
      lighter: "#fefcdc",
    },
    neutral: {
      main: "#EAEDEE",
      light: "#F2F4F5",
      lighter: "#FBFBFC",
    },
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    background: {
      main: "#F7F8F8",
    },
    error: "#FF0000",
    success: "#6FCF97",
    notification: "#F0B138",
  },
  onPalette: {
    primary1: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      lighter: "#092651",
    },
    primary2: {
      main: "#092651",
      light: "#092651",
      lighter: "#092651",
    },
    secondary1: {
      main: "#FFFFFF",
      light: "#092651",
      lighter: "#092651",
    },
    secondary2: {
      main: "#092651",
      light: "#092651",
      lighter: "#092651",
    },
    neutral: {
      main: "#092651",
      light: "#092651",
      lighter: "#092651",
    },
    common: {
      black: "#FFFFFF",
      white: "#092651",
    },
    background: {
      main: "#092651",
      white: "#092651",
    },
    error: "#FFFFFF",
    success: "#FFFFFF",
    notification: "#FFFFFF",
  },
  typography: {
    fontFamily: fontFamily,
    defaultColor: "#092651",
    h1: {
      fontFamily: fontFamilyHeading,
      fontSize: "5rem",
      fontSizeSm: "3.75rem",
      textTransform: "none",
    },
    h2: {
      fontFamily: fontFamilyHeading,
      fontSize: "2.5rem",
      fontSizeSm: "2.25rem",
      textTransform: "none",
    },
    h3: {
      fontFamily: fontFamilyHeading,
      fontSize: "2.25rem",
      fontSizeSm: "2rem",
      textTransform: "none",
    },
    h4: {
      fontFamily: fontFamilyHeading,
      textTransform: "none",
    },
    h5: {
      fontFamily: fontFamilyHeading,
      textTransform: "none",
    },
    h6: {
      fontFamily: fontFamilyHeading,
      textTransform: "none",
    },
    bodyLg: {
      fontFamily: fontFamily,
    },
    bodyMd: {
      fontFamily: fontFamily,
    },
    bodySm: {
      fontFamily: fontFamily,
    },
    bodyXs: {
      fontFamily: fontFamily,
    },
    button: {
      fontFamily: fontFamily,
    },
  },
});
