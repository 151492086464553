import { useState } from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { HowItWorks, ResultBox, SliderInput, isOomi } from "@vapaus/calculator";
import { LanguageSelectV2 } from "@vapaus/i18n";
import { Paper, Typography } from "@vapaus/ui-v2";

import { useGetCalculations } from "../api/calculations";
import { Footer } from "../components/Footer";
import { LogoLink } from "../components/LogoLink";
import { OomiFooter } from "../oomi";

const TopContent = styled.main`
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.palette.primary2.main} 70%, ${theme.palette.background.main} 70%)`};

  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      background: linear-gradient(
        180deg,
        ${theme.palette.primary2.main} 50%,
        ${theme.palette.background.main} 50%
      );
    `)}
`;

const OuterContainer = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.lg};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      margin: ${({ theme }) => `0 ${theme.spacing.sm} 0`};
    `)}
`;

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.spacing.lg} 0 0`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: ${({ theme }) => `${theme.spacing.lg} 0 0`};
    `)}
`;

const AppBar = styled.header`
  height: 75px;
  background-color: ${({ theme }) => theme.palette.primary2.main};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  display: flex;
  justify-content: space-between;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};
  > div {
    width: 50%;
  }

  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      flex-wrap: wrap;
      > div {
        width: 100%;
      }
    `)}
`;

const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
`;

export function App() {
  const { t } = useTranslation();
  const [data, setData] = useState({
    totalPackagePrice: 2000,
    monthlySalary: 3800,
  });
  const { data: calculation } = useGetCalculations(data, {
    keepPreviousData: true,
  });

  const handleChange =
    (field: "totalPackagePrice" | "monthlySalary") => (value: number) => {
      setData({ ...data, [field]: value });
    };

  return (
    <>
      <Helmet>
        <title>{t("calculator:pageTitle")}</title>
        <meta name="description" content={t("calculator:pageDescription")} />
      </Helmet>
      <AppBar>
        <LogoLink />
        <LanguageSelectV2 />
      </AppBar>
      <TopContent>
        <OuterContainer>
          <Container>
            <Typography variant="h1" align="center" colorOn="primary2.main">
              {t("calculator:heading")}
            </Typography>
            <Typography
              variant="bodyLg"
              align="center"
              colorOn="primary2.main"
              weight="bold"
            >
              {isOomi()
                ? t("oomiCalculator:subHeading")
                : t("calculator:subHeading")}
            </Typography>
            <Row>
              <Paper shadow="md">
                <InputColumn>
                  <SliderInput
                    label={t("calculator:packagePrice")}
                    helpText={t("calculator:packagePriceHelp")}
                    name="packagePrice"
                    step={50}
                    min={400}
                    max={4000}
                    value={data.totalPackagePrice}
                    onChange={handleChange("totalPackagePrice")}
                    data-testid="slider-package-price"
                  />
                  <SliderInput
                    label={t("calculator:monthlySalary")}
                    helpText={t("calculator:monthlySalaryHelp")}
                    name="monthlySalary"
                    step={100}
                    min={1100}
                    max={8000}
                    value={data.monthlySalary}
                    onChange={handleChange("monthlySalary")}
                    data-testid="slider-monthly-salary"
                  />
                </InputColumn>
              </Paper>
              <ResultBox calculation={calculation} />
            </Row>
          </Container>
        </OuterContainer>
      </TopContent>
      <OuterContainer>
        <Container>
          <Typography variant="bodySm">{t("calculator:footnote")}</Typography>
          <HowItWorks calculation={calculation} />
          {isOomi() ? <OomiFooter /> : <Footer />}
        </Container>
      </OuterContainer>
    </>
  );
}

export default App;
