import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Typography } from "@vapaus/ui-v2";

import { LogoLink } from "./LogoLink";

const Root = styled.footer`
  border-top: 2px dashed ${({ theme }) => theme.palette.primary1.main};
  padding-top: ${({ theme }) => theme.spacing.lg};
  padding-bottom: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      flex-direction: column;
    `)}
`;

const LinkContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  align-items: center;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      flex-direction: column;
    `)}
`;

export function Footer() {
  const { t } = useTranslation();
  return (
    <Root>
      <LinkContainer>
        <Typography
          decoration="none"
          weight="bold"
          as="a"
          href={t("calculator:footer.forEmployees.url")}
          target="_blank"
          rel="noopener"
        >
          {t("calculator:footer.forEmployees.label")}
        </Typography>
        <Typography
          decoration="none"
          weight="bold"
          as="a"
          href={t("calculator:footer.forEmployers.url")}
          target="_blank"
          rel="noopener"
        >
          {t("calculator:footer.forEmployers.label")}
        </Typography>
        <Typography
          decoration="none"
          weight="bold"
          as="a"
          href={t("calculator:footer.privacyPolicy.url")}
          target="_blank"
          rel="noopener"
        >
          {t("calculator:footer.privacyPolicy.label")}
        </Typography>
      </LinkContainer>
      <LogoLink />
    </Root>
  );
}
