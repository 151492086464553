import { StrictMode, Suspense } from "react";
import * as ReactDOM from "react-dom/client";

import { init } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";

import { isOomi } from "@vapaus/calculator";
import { CssBaseline, Fonts, createTheme } from "@vapaus/ui-v2";

import "./i18n";
import { OomiFavicon, OomiFonts, oomiTheme } from "./oomi";
import App from "./pages/App";

if (
  import.meta.env.VITE_ENV &&
  ["prod", "staging", "feature"].includes(import.meta.env.VITE_ENV)
) {
  init({
    dsn: "https://14282595f8c54f01b07fa2bd276d3dbb@o1371810.ingest.sentry.io/4505561034326016",
    integrations: [],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000, // 10 minutes
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Override the default theme
const theme = createTheme({
  typography: {
    h1: {
      fontSize: "6rem",
      fontSizeSm: "3.75rem",
    },
  },
});

root.render(
  <StrictMode>
    <ThemeProvider theme={isOomi() ? oomiTheme : theme}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback="loading">
          {isOomi() ? <OomiFonts /> : <Fonts />}
          {isOomi() && <OomiFavicon />}
          <CssBaseline />
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Suspense>
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>,
);
