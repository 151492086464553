import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  Typography,
  YoutubeIcon,
} from "@vapaus/ui-v2";

import { LogoLink } from "../components/LogoLink";

const Root = styled.footer`
  border-top: 2px dashed ${({ theme }) => theme.palette.primary1.main};
  padding-top: ${({ theme }) => theme.spacing.lg};
  padding-bottom: 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      align-items: center;
      flex-direction: column;
    `)}
`;

const LinkContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      align-items: center;
      flex-direction: column;
    `)}
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  align-items: flex-end;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      align-items: center;
    `)}
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
`;

export function OomiFooter() {
  const { t } = useTranslation("oomiCalculator");
  return (
    <Root>
      <LinkContainer>
        <Typography
          decoration="none"
          weight="bold"
          as="a"
          href={t("oomiCalculator:footer.privacyStatement.url")}
          target="_blank"
          rel="noreferrer"
        >
          {t("oomiCalculator:footer.privacyStatement.label")}
        </Typography>
      </LinkContainer>
      <RightContainer>
        <LogoLink />
        <SocialMediaContainer>
          <a
            href="https://www.facebook.com/oomienergia/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/oomienergia/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://twitter.com/EnergiaOomi"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/oomienergia/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinIcon />
          </a>
          <a
            href="https://www.youtube.com/channel/UCm7a2kaxTZU-BWcrv6XriTA"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </a>
        </SocialMediaContainer>
      </RightContainer>
    </Root>
  );
}
