import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";
import type { SetOptional } from "type-fest";

import {
  BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation,
  CalculationsApi,
  ResponseError,
  WithTaxDetailsBenefitCalculation,
} from "@vapaus/api-codegen";
import { BenefitCalculationWithExtra } from "@vapaus/calculator";

import { configuration } from "./_configuration";

const calculationsApi = new CalculationsApi(configuration);

type Params = SetOptional<
  BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation,
  "monthlyPayment" | "monthlyMaintenanceBudget" | "country"
>;

export const useGetCalculations = (
  params: Params,
  options?: Omit<
    UseQueryOptions<
      WithTaxDetailsBenefitCalculation,
      ResponseError,
      BenefitCalculationWithExtra
    >,
    "queryKey" | "queryFn" | "select"
  >,
) => {
  const params_: BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation =
    {
      monthlyPayment: 100,
      monthlyMaintenanceBudget: 0,
      country: "FI",
      ...params,
    };
  return useQuery<
    WithTaxDetailsBenefitCalculation,
    ResponseError,
    BenefitCalculationWithExtra
  >(
    ["calculations", params_],
    () =>
      calculationsApi.calculationsGetPublicFixedMonthlyPaymentWithTaxCalculation(
        {
          bodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation:
            params_,
        },
      ),
    {
      ...options,
      select(data: WithTaxDetailsBenefitCalculation) {
        return {
          ...data,
          ...params,
          costAfterSavings:
            data.totalValueAmount -
            data.taxAmountReduction +
            data.residualValue,
          savings:
            data.taxAmountReduction -
            (data.totalValueAmount - params.totalPackagePrice) -
            data.residualValue,
        };
      },
    },
  );
};
