import styled from "styled-components";

import logoDefault from "./oomi-logo.png";

const Img = styled.img`
  height: 40px;
`;

export function OomiLogo() {
  return <Img src={logoDefault} alt="Oomi logo" role="img" />;
}
