import { useEffect } from "react";

import favicon from "./oomi-favicon.png";

export function OomiFavicon() {
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as
      | HTMLLinkElement
      | undefined;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = favicon;
  }, []);
  return null;
}
