import { useTranslation } from "react-i18next";

import { isOomi } from "@vapaus/calculator";
import { Logo } from "@vapaus/ui-v2";

import { OomiLogo } from "../oomi";

export function LogoLink() {
  const { t } = useTranslation();

  return (
    <a
      href={
        isOomi()
          ? t("oomiCalculator:landingPageUrl")
          : t("calculator:landingPageUrl")
      }
    >
      {isOomi() ? <OomiLogo /> : <Logo />}
    </a>
  );
}
